import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DrawerMenuIcon from "../../assets/icons/Menu.svg";
import SiemensLogoWhite from "../../assets/images/siemens-logo-white.svg";
import HomeIcon from "@material-ui/icons/Home";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import PowerSettingsNewOutlinedIcon from "@material-ui/icons/PowerSettingsNewOutlined";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import "./SideDrawer.scss";
import { useSelector, useDispatch } from "react-redux";
import { storeLandingMenuItemsAction } from "../../Redux/Actions/actions";


const useStyles = makeStyles({
    list: {
        width: 300,
    },
    fullList: {
        width: "auto",
    },
});

export default function SideDrawer() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const [drawerState, setDrawerState] = React.useState({
        left: false,
    });
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const siemensToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const landingMenuItems = useSelector(state => state.storeLandingMenuItemsReducer.menuItems) || [];
    const toggleDrawer = (anchor, open) => (event) => {
        if (
            event &&
            event.type === "keydown" &&
            (event.key === "Tab" || event.key === "Shift")
        ) {
            return;
        }

        setDrawerState({ ...drawerState, [anchor]: open });
    };

    function logoutUser() {
        var newurl =
            window.location.protocol +
            "//" +
            window.location.host +
            window.location.pathname;
        window.history.pushState({ path: newurl }, "", `/`);
        localStorage.clear();
        sessionStorage.clear();
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });

        window.location.href = "/";
    }

    const sideDrawerItems = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Upload RTC Data",
            link: "UploadRTCExcelData",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "View RTC Data",
            link: "viewRTC",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Download RTC",
            link: "generateRTC",
        },
        {
            icon: <GroupAddIcon />,
            title: "Admin",
            link: "userManagement",
        },
        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",
        },
    ];

    const sideDrawerItemsForVendor = [
        { icon: <HomeIcon />, title: "Home", link: "/" },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Upload RTC Data",
            link: "UploadRTCExcelData",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Download RTC",
            link: "generateRTC",
        },
        {
            icon: <ListAltOutlinedIcon />,
            title: "Change Password",
            link: "changePassword",
        },
        {
            icon: <PowerSettingsNewOutlinedIcon />,
            title: "Logout",
            link: "logout",
        },
    ];
    const icons = {
        "<ListAltOutlinedIcon />": <ListAltOutlinedIcon />,

        "<HomeIcon />": <HomeIcon />,

        "<GroupAddIcon />": <GroupAddIcon />,

    };
    const list = (anchor) => (
        <div
            className={clsx(classes.list, {
                [classes.fullList]: anchor === "top" || anchor === "bottom",
            })}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Link to="/" className="d-flex jc-center side-drawer-logo">
                <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
            </Link>
            <List className="side-drawer-menu-list" style={{ marginTop: '-10px' }}>
                {landingMenuItems.map((item, index) =>
                (
                    <Link to={item.menuLink} key={index}>
                        <ListItem button>
                            <ListItemIcon className="side-drawer-icons">


                                {icons[item.menuIcon]}

                            </ListItemIcon>
                            <ListItemText primary={item.menuName} />
                        </ListItem>

                    </Link>

                )

                )}



            </List>
            <div className="sideDrawer-footer-block">
                <p>© 2021 Siemens Intranet</p>
            </div>
        </div>
    );

    function getLandingMenus() {
        const options = {
            method: "get",
            headers: {
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };
        return fetch("Role/GetLandingMenuData", options)
            .then((response) => response.json())
            .then((response) => {
                if (response) {
                    console.log(response.result)

                    dispatch(storeLandingMenuItemsAction(response.result));

                } else {

                    dispatch(storeLandingMenuItemsAction(response.result));
                }
            })
            .catch((error) => {
                console.log("Error: " + error, "error");
            });
    }
    useEffect(() => {
        if (landingMenuItems?.length <= 0) {
            getLandingMenus();
        }
    }, [userDetails, landingMenuItems]);

    //const listForVendor = (anchor) => (
    //    <div
    //        className={clsx(classes.list, {
    //            [classes.fullList]: anchor === "top" || anchor === "bottom",
    //        })}
    //        role="presentation"
    //        onClick={toggleDrawer(anchor, false)}
    //        onKeyDown={toggleDrawer(anchor, false)}
    //    >
    //        <Link to="/" className="d-flex jc-center side-drawer-logo">
    //            <img src={SiemensLogoWhite} alt="Siemens" className="official-logo" />
    //        </Link>
    //        <List className="side-drawer-menu-list">
    //            {sideDrawerItemsForVendor.map((item, index) =>
    //                item.link === "logout" ? (
    //                    <ListItem button onClick={logoutUser} key={index}>
    //                        <ListItemIcon className="side-drawer-icons">
    //                            {item.icon}
    //                        </ListItemIcon>
    //                        <ListItemText primary={item.title} />
    //                    </ListItem>
    //                ) : (
    //                    <Link to={item.link} key={index}>
    //                        <ListItem button>
    //                            <ListItemIcon className="side-drawer-icons">
    //                                {item.icon}
    //                            </ListItemIcon>
    //                            <ListItemText primary={item.title} />
    //                        </ListItem>
    //                    </Link>
    //                )
    //            )}
    //        </List>
    //        <div className="sideDrawer-footer-block">
    //            <p>© 2021 Siemens Intranet</p>
    //        </div>
    //    </div>
    //);

    return (
        <React.Fragment>
            {["left"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <img
                        src={DrawerMenuIcon}
                        alt="Besys Menu"
                        className="besys-menu-icon"
                        onClick={toggleDrawer(anchor, true)}
                    />
                    <SwipeableDrawer
                        anchor={anchor}
                        open={drawerState[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                    >
                        {list(anchor)}
                    </SwipeableDrawer>
                    
                   {/* )}*/}

                </React.Fragment>
            ))}
        </React.Fragment>
    );
}
