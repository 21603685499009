import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import MaterialTable from "material-table";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";

export default function CreateRTC() {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openAlertDialog, setOpenAlertDialog] = useState(false);

  const data = [
    {
      machineNo: "65279062",
      client: "Gupta Electric Company",
      efficiencyClass: "IE2",
      salesOrder: "3006981707",
      salesOrderItem: "200",
      produCtcode: "1LE76012DB035AA4-Z",
      output: "55.00",
      current: "98.00",
      volt: "450.00",
      speed: "1486",
      freq: "50.00",
      efficiency: "94.00",
      powerFactor: "0.83",
      connection: "Delta",
      protection: "IP55",
      duty: "S1",
      insulationClass: "155(F)1100",
      frameSize: "280S",
      ambientTemp: "IEC 60034-1",
      refstd: "IEC 60034-1",
      direction: "CLOCKWISE(DE)",
      deBearing: "6317-C3",
      ndeBearing: "6317-C3",
      construction: "IMB3",
      tbox: "TB TOP",
      motorType: "7CV2280B",
      y82: "1B1M022 VOLTAGE ±15%, FREQUENCY ±6%",
      vfd: "Yes",
      btd: "",
      ach: "240V/117W",
      ptc: "",
      rtc: "",
      zcode: "B59+B65+Q07+R62+Y56+Y82",
      polarity: "4",
      avgRes: "0.0562",
      ambTemp: "28",
      voltNLTest: "415",
      cur1NLTest: "34.1",
      cur2NLTest: "33.6",
      cur3NLTest: "32.9",
      powerNLTest: "1767",
      voltLRTest: "71.1",
      curLRTest: "98",
      inputLRTest: "3930",
      voltHVTest: "2",
      durationHVTest: "1min",
      remarksHVTest: "OK",
      insulation: ">50 MOHMS",
      date: "25.01.2021",
      vibration: "",
      edesign: "",
      makende: "",
      makeNDE: "",
      btdRes: "",
      achRes: "",
      ptcRes: "",
      rtdRes: "",
      ktyRes: "",
      max: "1.89",
    },
    {
      machineNo: "65279063",
      client: "Gupta Electric Company",
      efficiencyClass: "IE2",
      salesOrder: "3006981707",
      salesOrderItem: "200",
      produCtcode: "1LE76012DB035AA4-Z",
      output: "55.00",
      current: "98.00",
      volt: "450.00",
      speed: "1486",
      freq: "50.00",
      efficiency: "94.00",
      powerFactor: "0.83",
      connection: "Delta",
      protection: "IP55",
      duty: "S1",
      insulationClass: "155(F)1100",
      frameSize: "280S",
      ambientTemp: "IEC 60034-1",
      refstd: "IEC 60034-1",
      direction: "CLOCKWISE(DE)",
      deBearing: "6317-C3",
      ndeBearing: "6317-C3",
      construction: "IMB3",
      tbox: "TB TOP",
      motorType: "7CV2280B",
      y82: "1B1M022 VOLTAGE ±15%, FREQUENCY ±6%",
      vfd: "Yes",
      btd: "",
      ach: "240V/117W",
      ptc: "",
      rtc: "",
      zcode: "B59+B65+Q07+R62+Y56+Y82",
      polarity: "4",
      avgRes: "0.0562",
      ambTemp: "28",
      voltNLTest: "415",
      cur1NLTest: "34.1",
      cur2NLTest: "33.6",
      cur3NLTest: "32.9",
      powerNLTest: "1767",
      voltLRTest: "71.1",
      curLRTest: "98",
      inputLRTest: "3930",
      voltHVTest: "2",
      durationHVTest: "1min",
      remarksHVTest: "OK",
      insulation: ">50 MOHMS",
      date: "25.01.2021",
      vibration: "",
      edesign: "",
      makende: "",
      makeNDE: "",
      btdRes: "",
      achRes: "",
      ptcRes: "",
      rtdRes: "",
      ktyRes: "",
      max: "1.89",
    },
  ];

  const columns = [
    { title: "Machine No", field: "machineNo" },
    { title: "Client", field: "client" },
    { title: "Efficiency Class", field: "efficiencyClass" },
    { title: "Sales Order", field: "salesOrder" },
    { title: "Sales Order item", field: "salesOrderItem" },
    { title: "Product Code", field: "produCtcode" },
    { title: "Output", field: "output" },
    { title: "Current", field: "current" },
    { title: "Voltage", field: "volt" },
    { title: "Speed", field: "speed" },
    { title: "Frequency", field: "freq" },
    { title: "Efficiency %", field: "efficiency" },
    { title: "Power Factor", field: "powerFactor" },
    { title: "Connection", field: "connection" },
    { title: "Protection", field: "protection" },
    { title: "Duty", field: "duty" },
    { title: "Insulation Class", field: "insulationClass" },
    { title: "Frame Size", field: "frameSize" },
    { title: "Ambient Temp.", field: "ambientTemp" },
    { title: "Ref.std.", field: "refstd" },
    { title: "Direction", field: "direction" },
    { title: "DE Bearing", field: "deBearing" },
    { title: "NDE Bearing", field: "ndeBearing" },
    { title: "Construction", field: "construction" },
    { title: "T.Box Position", field: "tbox" },
    { title: "Motor TYpe", field: "motorType" },
    { title: "Y82", field: "y82" },
    { title: "VFD", field: "vfd" },
    { title: "BTD", field: "btd" },
    { title: "ACH", field: "ach" },
    { title: "PTC", field: "ptc" },
    { title: "RTC", field: "rtc" },
    { title: "Z Code", field: "zcode" },
    { title: "Polarity", field: "polarity" },
    { title: "Connection", field: "connection" },
    { title: "Avg.Res.", field: "avgRes" },
    { title: "Amb.Temp.", field: "ambTemp" },
    { title: "Voltage NLTest", field: "voltNLTest" },
    { title: "Current_1 NLTest", field: "cur1NLTest" },
    { title: "Current_2 NLTest", field: "cur2NLTest" },
    { title: "Current_3 NLTest", field: "cur3NLTest" },
    { title: "Input Power NLTest", field: "powerNLTest" },
    { title: "Voltage LRTest", field: "voltLRTest" },
    { title: "Current LRTest", field: "curLRTest" },
    { title: "Input Power LRTest", field: "inputLRTest" },
    { title: "Voltage HVTest", field: "voltHVTest" },
    { title: "Duration HVTest", field: "durationHVTest" },
    { title: "Remarks HVTest", field: "remarksHVTest" },
    { title: "Insulation Resistance", field: "insulation" },
    { title: "Date", field: "date" },
    { title: "Vibration", field: "vibration" },
    { title: "EDesign/Calculation Sheet", field: "edesign" },
    { title: "Make-DE Bearing", field: "makeDE" },
    { title: "Make-NDE Bearing", field: "makeNDE" },
    { title: "BTD Res.", field: "btdRes" },
    { title: "ACH Res.", field: "achRes" },
    { title: "PTC Res.", field: "ptcRes" },
    { title: "RTD Res.", field: "rtdRes" },
    { title: "KTY Res.", field: "ktyRes" },
    { title: "MAX. % UNBALANCE NLC", field: "max" },
  ];
  const handleErrorBackButton = () => {
    setOpenErrorDialog(false);
  };
  const handleErrorClose = () => {
    setOpenErrorDialog(false);
  };
  const handleAlertClose = () => {
    setOpenAlertDialog(false);
  };
  const handleAlertOpen = () => {
    setOpenAlertDialog(true);
  };
  const handleErrorOpen = () => {
    setOpenErrorDialog(true);
  };

  useEffect(() => {}, []);

  const handleRowUpdate = (newTableData, oldTableData, resolve, reject) => {};
  const handleRowAdd = (newTableData, oldTableData, resolve, reject) => {};
  const handleRowDelete = (oldTableData, resolve, reject) => {};
  return (
    <div>
      <Grid container spacing={2}>
        <Grid container spacing={2} item xs={12} className="d-flex jc-space-bt">
          <Grid item xs={12} className="text-left ml-1">
            <h2>Create RTC</h2>
          </Grid>
          <Grid container spacing={2} item xs={12} className="al-center">
            <Grid item xs={12} md={4}>
              <TextField
                type="file"
                label="Upload File"
                id=""
                name=""
                className=""
                fullWidth
                variant="filled"
                InputLabelProps={{ shrink: true }}
              ></TextField>
            </Grid>
            <Grid item xs={3} md={1}>
              <Button
                type="submit"
                fullWidth
                className="pt-button--primary"
                id="submitbtn"
                onClick={handleAlertOpen}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <MaterialTable
            title="User List"
            columns={columns}
            data={data}
            icons={tableIconsInputScreen}
            //isLoading={isLoader}
            options={tableOptionsUser}
            editable={{
              onRowUpdate: (newTableData, oldTableData) =>
                new Promise((resolve, reject) => {
                  handleRowUpdate(newTableData, oldTableData, resolve, reject);
                }),
              onRowAdd: (newTableData) =>
                new Promise((resolve, reject) => {
                  handleRowAdd(newTableData, resolve, reject);
                }),
              onRowDelete: (oldTableData) =>
                new Promise((resolve, reject) => {
                  handleRowDelete(oldTableData, resolve, reject);
                }),
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        onClose={handleErrorClose}
        disableBackdropClick
        aria-labelledby="rtc-form--error"
        open={openErrorDialog}
        maxWidth={"lg"}
      >
        <DialogTitle id="rtc-form--error" onClose={handleErrorClose}>
          Error:-
        </DialogTitle>
        <DialogContent>
          <p>Error Message</p>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            fullWidth
            className="pt-button--primary"
            id="submitbtn"
          >
            Download Log
          </Button>
          <Button
            type="submit"
            fullWidth
            className="pt-button--secondary"
            id="submitbtn"
            onClick={handleErrorClose}
          >
            close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        onClose={handleAlertClose}
        disableBackdropClick
        aria-labelledby="rtc-form--error"
        open={openAlertDialog}
        maxWidth={"lg"}
      >
        <DialogTitle id="rtc-form--error" onClose={handleErrorClose}>
          Alert:-
        </DialogTitle>
        <DialogContent>
          <p>Machine Numbers mentioned below are already existing.</p>
          <p>65279063</p>
          {/* <Grid container spacing={2}></Grid> */}
          <p>
            In oder to make changes to existing Machine Numbers, click on Submit
            button.
          </p>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            fullWidth
            className="pt-button--secondary"
            id="submitbtn"
            onClick={handleAlertClose}
          >
            Close
          </Button>
          <Button
            type="submit"
            fullWidth
            className="pt-button--primary"
            id="submitbtn"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
