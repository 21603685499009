import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
    Container,
    Grid,
    Button,
    TextField,
    InputAdornment,
    IconButton,
    ListItemText,
    ListItem,
} from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import ClearIcon from "@material-ui/icons/Clear";
//import Loading from "../Components/Loading/Loading";
import Loader from "../../Components/Loader/Loader";
//import { ResetUserPassword } from "../../Redux/API/api_UserManagement";
import { ResetUserPassword } from "../../APIs/api_UserManagement";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

import { useHistory } from "react-router";
import { logoutUser } from "../../Utilities/Utility";

import "../ChangePassword/ChangePassword.scss";

export default function ChangePassword() {
    const [newPassword, setNewPassowrd] = useState("");
    const [newPasswordHelper, setNewPassowrdHelper] = useState("");
    const [confirmPassword, setConfirmPassowrd] = useState("");
    const [confirmPasswordHelper, setConfirmPassowrdHelper] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const siteToken = useSelector((state) => state.saveTokenReducer);
    const [isLoader, setIsLoader] = useState(false);
    const [oldPassword, setOldPassword] = useState("");
    const [oldPasswordHelper, setOldPasswordHelper] = useState("");

    let history = useHistory();
    const [openSnack, setOpenSnack] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [snackAlertType, setSnackAlertType] = useState("");
    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setSnackAlertType(type);
        setOpenSnack(true);
    };
    const handleClose = (e) => {
        setOpenSnack(false);
         //history.push({
         //  pathname: "/",
         //});
    };

    const userData = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const isForgotPassword = useSelector(
        (state) => state.isForgotPasswordReducer
    );
    
    const isForgotPasswordClicked = useSelector(state => state.isForgotPasswordReducer);
    
       const userMailID = userData.emailId;
        //const userMailID = userData.userEmail;
    

    function validatePasswordComponent(value) {
        const minMaxLength = /^[\s\S]{8,}$/,
            upper = /[A-Z]/,
            lower = /[a-z]/,
            number = /[0-9]/,
            special = /[!#$%&'_()*+,-./:;<=>?@[\]^`{|}~]/;
        return !upper.test(value)
            ? "1 uppercase must"
            : !lower.test(value)
                ? "1 lowercase must"
                : !number.test(value)
                    ? "1 integer must"
                    : !special.test(value)
                        ? "1 special char must"
                        : !minMaxLength.test(value)
                            ? "Minimum length 8"
                            : "";
    }

    const handlePasswordChange = (event) => {        let valid;

        switch (event.target.id) {
            case "newPassword":
                setNewPassowrd(event.target.value);
                if (!event.target.value) {
                    setNewPassowrdHelper("*Required");
                } else {
                    setNewPassowrdHelper(validatePasswordComponent(event.target.value));
                }
                break;
            case "confirmPassword":
                setConfirmPassowrd(event.target.value);
                if (event.target.value !== newPassword) {
                    setConfirmPassowrdHelper("Password does not match with New Password");
                } else {
                    setConfirmPassowrdHelper("");
                }
                break;
            case "oldPassword":
                 setOldPassword(event.target.value);
                if (event.target.value !==userData.userPassword) {
                    setOldPasswordHelper("Password does not match with old Password");
                } else {
                    setOldPasswordHelper("");
                }
                break;

            default:
                break;
        }
    };
    const handleClearDataNew = (event) => {
        setNewPassowrd("");
        setOldPassword("");
    };
    const handleClearDataConfirm = (event) => {
        setConfirmPassowrd("");
        setOldPassword("");
    };

    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownShowPassword = () => setShowPassword(!showPassword);

    const handleClickShowPasswordConfirm = () =>
        setShowPasswordConfirm(!showPasswordConfirm);
    const handleMouseDownShowPasswordConfirm = () =>
        setShowPasswordConfirm(!showPasswordConfirm);

    function changePasswordReq() {
         //ResetUserPassword
         if(isForgotPasswordClicked && newPassword === confirmPassword) {
            resetPassword();
         }
        else if (newPassword === confirmPassword && oldPassword === userData.userPassword) {
            resetPassword();
        }
        else {
            handleClearDataNew();
            handleClearDataConfirm();
            setIsLoader(false);
            handleSnackOpen("cannot change the password ","error");
        }
    }

    async function resetPassword() {
        setIsLoader(true);
        const data = {
            
            EmailId: userMailID,
            Password: newPassword,
        };
        ResetUserPassword(siteToken, data)
            .then((response) => {
                if (response.response) {
                    handleClearDataNew();
                    handleClearDataConfirm();
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "success");
                    //logoutUser();
                } else {
                    setIsLoader(false);
                    handleSnackOpen(response.responseMsg, "error");
                }
            })
            .catch((error) => {
                handleClearDataNew();
                handleClearDataConfirm();
                setIsLoader(false);
                handleSnackOpen("Password Changed Failed!", "error");
            });
    }
    const handleClickBack = (e) => {
        if (isForgotPassword) {
            logoutUser();
        }
        
         else {
            history.goBack();
        }
    };

    const specialChars = " (e.g. !#$%&'()*+,-./:;<=>?@[\\]^`{|}~)";
    return (
        <>
            <div className="master-layout-block d-flex jc-center">
                <Grid
                    xs={12}
                    md={7}
                    lg={7}
                    sm={12}
                    className="d-flex jc-center fd-column"
                >
                    <Grid container xs={12} className="change-password-container">
                        <Grid container xs={7} spacing={1} className="">
                            <Grid item xs={12} sm={12}>
                                <h3 className="text-center">Change Password</h3>
                            </Grid>
                            
                            {!isForgotPasswordClicked ? (<Grid item xs={12} sm={12} className="jc-center">
                                <TextField
                                    type={showPassword ? "text" : "password"}
                                    label="Old Password *"
                                    variant="filled"
                                    fullWidth
                                    value={oldPassword}
                                    onChange={handlePasswordChange}
                                    error={oldPasswordHelper.length !== 0}
                                    helperText={oldPasswordHelper}
                                    id="oldPassword"
                                    className="pt-login-form-input ml-1"
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={handleClearDataNew}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownShowPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>):<></>}
                            {/*<Grid item xs={12} sm={12}>*/}
                            {/*    <h3 className="text-center">Change Password</h3>*/}
                            {/*</Grid>*/}
                           
                            <Grid item xs={12} sm={12} className="jc-center " style={{marginTop:"10px"} }>
                                <TextField
                                    type={showPassword ? "text" : "password"}
                                    label="New Password *"
                                    variant="filled"
                                    fullWidth
                                    value={newPassword}
                                    onChange={handlePasswordChange}
                                    error={newPasswordHelper.length !== 0}
                                    helperText={newPasswordHelper}
                                    id="newPassword"
                                    className="pt-login-form-input ml-1 "
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={handleClearDataNew}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownShowPassword}
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                                </Grid>
                               
                            <Grid item xs={12} sm={12} className="jc-center">
                                <TextField
                                    type={showPasswordConfirm ? "text" : "password"}
                                    label="Confirm New Password *"
                                    variant="filled"
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={handlePasswordChange}
                                    error={confirmPasswordHelper.length !== 0}
                                    helperText={confirmPasswordHelper}
                                    id="confirmPassword"
                                    className="pt-login-form-input mt-1 mb-1 ml-1"
                                    InputProps={{
                                        endAdornment: (
                                            <>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={handleClearDataConfirm}
                                                    >
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        className="password-adornment"
                                                        onClick={handleClickShowPasswordConfirm}
                                                        onMouseDown={handleMouseDownShowPasswordConfirm}
                                                    >
                                                        {showPasswordConfirm ? (
                                                            <Visibility />
                                                        ) : (
                                                            <VisibilityOff />
                                                        )}
                                                    </IconButton>
                                                </InputAdornment>
                                            </>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} className="d-flex jc-center mb-1">
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12} sm={4}>
                                        <Button
                                            fullWidth
                                            className="pt-button--secondary bulk-upload-submit-btn"
                                            id="btnBackAtChangePWD"
                                            onClick={handleClickBack}
                                        >
                                            Back
                                        </Button>
                                    </Grid>
                                    <Grid item xs={12} sm={4} className="ml-2">
                                        <Button
                                            fullWidth
                                            className="pt-button--primary bulk-upload-submit-btn"
                                            id="ChangePwdBtn"
                                            onClick={changePasswordReq}
                                        >
                                            Change
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container xs={1}>
                            <Grid item xs={12}>
                                {/* <hr className="dashed" /> */}
                            </Grid>
                        </Grid>
                        <Grid container xs={4} spacing={1} className="">
                            <Grid item xs={12} sm={12}>
                                <h3 className="text-center">Password Policy</h3>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <ol className="mb-7e f-12 fw-bold vl">
                                    <li>Password must be of atleast 8 characters.</li>
                                    <li>Password must contains atleast 1 capital letter.</li>
                                    <li>Password must contains atleast 1 number</li>
                                    <li>
                                        Password must contains atleast 1 special character
                                        {specialChars}
                                    </li>
                                    {/* <br />
                  <li>
                    It is recommended :
                    <ol type="a">
                      <li>
                        Password must be changed while logging in for the first
                        time.
                      </li>
                      <li>Password must changed in 90 days interval.</li>
                    </ol>
                  </li> */}
                                </ol>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {isLoader ? <Loader /> : <></>}
            <SnackbarCustom
                open={openSnack}
                message={snackMessage}
                alertType={snackAlertType}
                handleClose={handleClose}
            />
        </>
    );
}
