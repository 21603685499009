import { Button, Container } from "@material-ui/core";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { storeUserDetailsAction } from "../../Redux/Actions/actions";

export default function UnauthorizedAccess(props) {
  const dispatch = useDispatch();
  function logoutUser(auth, dispatch) {
    var newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname;
    window.history.pushState({ path: newurl }, "", `/`);
    localStorage.clear();
    sessionStorage.clear();
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
    // if (auth !== undefined) {
    //   //auth.removeUser();
    //   // dispatch(saveAccessTokenAction(''));
    //   dispatch(storeUserDetailsAction(""));

    //   caches.keys().then((names) => {
    //     names.forEach((name) => {
    //       caches.delete(name);
    //     });
    //   });
    // }
    window.location.href = "/";
  }
  var newurl =
    window.location.protocol +
    "//" +
    window.location.host +
    window.location.pathname;
  window.history.pushState({ path: newurl }, "", `/`);
  return (
    <Container maxWidth="sm">
      <div className="master-layout-block">
        <h2 className="clr-red">
          {props.errorText
            ? props.errorText
            : "WARNING!!! You are not authorized to access this page."}
        </h2>
        <h4 className="clr-white">
          Contact Administrator if there is an issue with your request.
        </h4>
        <Link to="/">
          <Button
            onClick={(auth) => logoutUser(auth, dispatch)}
            className="pt-button--tertiary"
          >
            &lt; LoginPage
          </Button>
        </Link>
      </div>
    </Container>
  );
}
