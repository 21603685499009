import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ThemeProvider } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { lightTheme } from "./Themes/LightTheme";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { store, persistor } from "./Redux/Store/store";
import { Auth0Provider } from '@auth0/auth0-react';
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';



const oidcConfig = {
    authority: "https://myid-qa.siemens.com:443",
    client_id: "066f5cc2-0478-45fe-b51e-10ea58f2b553", //92ac572c-5986-405c-a5f9-a483aa63f24e
    client_secret: "APDsPIMPiUEbwq0CkP", //5n2uEgAls8hbZuj6ze
    redirect_uri: `${window.location.origin}`,
    response_type: "code",
    post_logout_redirect_uri: `${window.location.origin}`,
    silent_redirect_uri: `${window.location.origin}`,
    automaticSilentRenew: true,
    requireHttpsMetadata: false,
    getClaimsFromUserInfoEndpoint: true,
};
document.body.style.zoom = `90%`;

//const msalInstance = new PublicClientApplication(msalConfig);
//// Default to using the first account if no account is active on page load
//if (msalInstance.getActiveAccount() != null && msalInstance.getAllAccounts().length > 0) {
//    // Account selection logic is app dependent. Adjust as needed for different use cases.
//    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
//}

// Listen for sign-in event and set active account
//msalInstance.addEventCallback((event) => {
//    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
//        const account = event.payload.account;
//        msalInstance.setActiveAccount(account);
//    }
//});
const allowedConnections = ['microsoft', 'google', 'github'];
ReactDOM.render(
    //<Provider store={store}>
    //    <PersistGate loading={null} persistor={persistor}>
    //        <MsalProvider instance={msalInstance}>
    //            <AuthProvider>
    //                <ThemeProvider theme={lightTheme}>
    //                    <CssBaseline />
    //                    <App />
    //                </ThemeProvider>
    //            </AuthProvider>
    //        </MsalProvider>
    //    </PersistGate>
    //</Provider>,
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Auth0Provider
                domain="qa-op.siemens.auth0app.com"
                clientId="vI7yQczJc58zYoaDYI1IYNA6154mLt2L"

                authorizationParams={{
                    redirect_uri: window.location.origin,
                    audience: `https://qa-op.siemens.auth0app.com/api/v2/`,
                    /*connection: allowedConnections.join(' '),*/
                    //display: "amazon-off|apple-off|azure-social-common-off|azure-social-SAG-off|cidp-off|facebook-off|flender-off|github-off|google-off|linkedin-off|wechat-off|gitlab-off",
                    display: "amazon-off|apple-off|azure-social-SAG-off|facebook-off|flender-off|github-off|gitlab-off|google-off|linkedin-off|azure-social-common-off|wechat-off|sup",
                    post_logout_redirect_uri: window.location.origin,
                }}
            >
                <ThemeProvider theme={lightTheme}>
                    <CssBaseline />
                    <App />
                </ThemeProvider>
            </Auth0Provider>
        </PersistGate>
    </Provider>,

    document.getElementById("root")
);
