import React, { useEffect, useState } from "react";
import axios from "axios";
import {
    TextField,
    Button,
    Grid,
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import MaterialTable from "material-table";
import Loader from "../../Components/Loader/Loader";
import { blue } from "@material-ui/core/colors";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import { tableOptionsUser } from "../../Themes/LightTheme";
import RTC_ManualUpload from "../../Common/RTC_UploadTemplate.xlsx";
import ViewRTC from "../../Screens/ViewRTC/ViewRTC";
import { useSelector, useDispatch } from "react-redux";
import "./Create.scss";
import SnackbarCustom from "../../Components/Snackbar/Snackbar";

export default function UploadRTCExcelData() {
    //const classes = useStyles();
    const [fileValue, setfileValue] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [inputFileValue, setInputFileValue] = useState(Date.now);
    const api = axios.create({
        baseURL: "api/RTCAPI",
    });

    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const siemensToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    useEffect(() => {
        getRtcDetails();
    }, []);

    const [uploadRes, setUploadRes] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const getRtcDetails = () => {
        api
            .get("/getRTCDetails")
            .then((res) => {
                //console.log(res.data);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            });
    };
    function filePathset_Value(e) {
        e.stopPropagation();
        e.preventDefault();
        var valuetemplatefile = e.target.files[0];
        var elementSAP = document.getElementById("RTCUploadFile");
        var element1SAP = elementSAP.parentElement.parentElement;
        setSelectedFile(e.target.files[0]);
        element1SAP.classList.remove("udi-error");
        if (valuetemplatefile !== undefined) {
            var extension =
                valuetemplatefile.name.split(".")[
                valuetemplatefile.name.split(".").length - 1
                ];
            if (extension.toString().toUpperCase() === "XLSX") {
                setfileValue(valuetemplatefile);
            } else {
                setfileValue(null);
                document.getElementById("RTCUploadFile").value = null;
                alert("Upload Excel file only.");
                //showMsg(true, "Upload Excel file only.", alerterrorclass, alerterror);
            }
        }
    }

    //for snackBar
    const [open, setOpen] = useState(false);
    const [snackMessage, setSnackMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    const handleSnackOpen = (text, type) => {
        setSnackMessage(text);
        setAlertType(type);
        setOpen(true);
    };
    const handleClose = (e) => {
        setOpen(false);
    };

    function submitDatacall() {
        //submitValueDetails();
        if (selectedFile !== null && selectedFile !== undefined) {
            setIsLoading(true);
            findExistingMacNo();
            document.getElementById("RTCUploadFile").value = null;
        } else {
            handleSnackOpen("File Not Selected.", "warning");
        }
    }

    function submitValueDetails() {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("UserId", 2);
        UploadRTCList(formData);
        setSelectedFile(null);
        setOpenAlertDialog(false);
    }
    function UploadRTCList(formData) {
        setIsLoading(true);
     
        const options = {
            method: "post",
            headers: {
            
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
            body: formData,
        };
        return fetch("api/RTCAPI/RTCDataUpload", options)
            .then((response) => response.json())
            .then((response) => {
                const resp = response.result;
                console.log(response.result);
                setUploadRes(response.result);
                if (resp.invalidFileUploaded == 1) {
                    setIsLoading(false);
                    handleSnackOpen("Invalid File Uploaded.", "error");
                }
                else if (resp.errorMessage) {
                    setIsLoading(false);
                    handleSnackOpen(resp.errorMessage, "error");
                }
                else {
                    if (resp.failedCnt > 0 || resp.successCnt > 0) {
                        setIsLoading(false);
                        handleSnackOpen("Records Updated Successfully.");
                    } else {
                        setIsLoading(false);
                        handleSnackOpen("No Records found in Uploaded Excel");
                    }
                }
                // findExistingMacNo();
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                setIsLoading(false);
                handleSnackOpen("Error in Inserting Data", "error");
            });
    }
    //function UploadRTCList(formData) {
    //    setIsLoading(true);
    //    api
    //        .post("/RTCDataUpload/" + userDetails.loginId, formData)
    //        .then((response) => {
    //            /* setIsLoading(false);*/
    //            const resp = response.data.result;
    //            console.log(response.data.result);
    //            setUploadRes(response.data.result);
    //            if (resp.invalidFileUploaded == 1) {
    //                setIsLoading(false);
    //                handleSnackOpen("Invalid File Uploaded.","error");
    //            }
    //            else if (resp.errorMessage)
    //            {
    //                setIsLoading(false);
    //                handleSnackOpen(resp.errorMessage, "error");
    //            }
    //            else {
    //                if (resp.failedCnt > 0 || resp.successCnt > 0) {
    //                    setIsLoading(false);
    //                    handleSnackOpen("Records Updated Successfully.");
    //                } else {
    //                    setIsLoading(false);
    //                    handleSnackOpen("No Records found in Uploaded Excel");
    //                }
    //            }
    //            // findExistingMacNo();
    //        })
    //        .catch((error) => {
    //            console.log(error.response);
    //            console.log("Error in hitting api at page load.", error);
    //            setIsLoading(false);
    //            handleSnackOpen("Error in Inserting Data", "error");
    //        });
    //}

    const [existingMacNos, setExistingMacNos] = useState([]);
    function findExistingMacNo() {
        setIsLoading(true);
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("UserId", 2);
        const options = {
            method: "post",
            headers: {

                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
            body: formData,
        };
        return fetch("api/RTCAPI/RTCExistingData", options)
            .then((response) => response.json())
            .then((response) => {
                /* setIsLoading(false);*/
                const resp = response.result;
                console.log(resp);
                setExistingMacNos(resp);
                if (resp.length > 0) {
                    setIsLoading(false);
                    handleAlertOpen("");
                } else {
                    submitValueDetails();
                }
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
                /*setIsLoading(false);*/
            });
    }
    //function findExistingMacNo() {
    //    setIsLoading(true);
    //    const formData = new FormData();
    //    formData.append("file", selectedFile);
    //    formData.append("UserId", 2);
    //    api
    //        .post("/RTCExistingData", formData)
    //        .then((response) => {
    //            /* setIsLoading(false);*/
    //            const resp = response.data.result;
    //            console.log(resp);
    //            setExistingMacNos(resp);
    //            if (resp.length > 0) {
    //                setIsLoading(false);
    //                handleAlertOpen("");
    //            } else {
    //                submitValueDetails();
    //            }
    //        })
    //        .catch((error) => {
    //            setIsLoading(false);
    //            console.log(error.response);
    //            console.log("Error in hitting api at page load.", error);
    //            /*setIsLoading(false);*/
    //        });
    //}
    const columns = [
        { title: "Machine No", field: "machine_No" },
        { title: "Client", field: "client" },
        { title: "Purchase Order", field: "purchase_Order" },
        { title: "PO Item", field: "pO_Item" },
        { title: "Efficiency Class", field: "efficiency_Class" },
        { title: "Sales Order", field: "sales_Order" },
        { title: "Sales Order item", field: "sales_Order_item" },
        { title: "Product Code", field: "product_Code" },
        { title: "Output", field: "output_KW" },
        { title: "Current", field: "current_A" },
        { title: "Voltage", field: "voltage_V" },
        { title: "Tolerance For Voltage%", field: "tolerance_Of_Voltage%" },
        { title: "Speed", field: "speed_RPM" },
        { title: "Frequency", field: "frequency_Hz" },
        { title: "Tolerance For Frequency%", field: "tolerance_Of_Frequency%" },
        { title: "Efficiency %", field: "efficiency%" },
        { title: "Power Factor", field: "power_Factor" },
        { title: "Connection", field: "connection" },
        { title: "Protection", field: "protection" },
        { title: "Duty", field: "duty" },
        { title: "Insulation Class", field: "insulation_Class" },
        { title: "Frame Size", field: "frame_Size" },
        { title: "Ambient Temp.", field: "ambient_Temp." },
        { title: "Ref.std.", field: "ref.std." },
        { title: "Direction", field: "direction" },
        { title: "DE Bearing", field: "dE_Bearing" },
        { title: "NDE Bearing", field: "ndE_Bearing" },
        { title: "Construction", field: "construction" },
        { title: "T.Box Position", field: "t.Box_Position" },
        { title: "Motor TYpe", field: "motor_Type" },
        { title: "Y82", field: "y82_/Aux_Plate" },
        { title: "VFD", field: "vfd" },
        { title: "BTD", field: "btd" },
        { title: "ACH", field: "ach" },
        { title: "PTC", field: "ptc" },
        { title: "RTD", field: "rtd" },
        { title: "Z Code", field: "z_Code" },
        { title: "Polarity", field: "polarity" },
        { title: "Connection", field: "connection2" },
        { title: "Avg.Res.", field: "avg.Res." },
        { title: "Amb.Temp.", field: "amb.Temp." },
        { title: "Voltage NLTest", field: "voltage_NLTest_Volt" },
        { title: "Current_1 NLTest", field: "current_1_NLTest_Amp" },
        { title: "Current_2 NLTest", field: "current_1_NLTest_Amp" },
        { title: "Current_3 NLTest", field: "current_3_NLTest_Amp" },
        { title: "Input Power NLTest", field: "input_Power_NLTest_Watt" },
        { title: "Voltage LRTest", field: "voltage_LRTest_Volt" },
        { title: "Current LRTest", field: "current_LRTest_Amp" },
        { title: "Input Power LRTest", field: "input_Power_LRTest_Watt" },
        { title: "Voltage HVTest", field: "voltage_HVTest_KV" },
        { title: "Duration HVTest", field: "duration_HVTest" },
        { title: "Remarks HVTest", field: "remarks_HVTest" },
        { title: "Insulation Resistance", field: "insulation_Resistance_Stator(Mega_Ohm)" },
        { title: "Insulation Resistance Accessories", field: "insulation_Resistance_Accessories(Mega_Ohm)" },
        { title: "Date", field: "date" },
        { title: "Vibration", field: "vibration" },
        {
            title: "EDesign/Calculation Sheet",
            field: "eDesign/Calculation_sheet",
        },
        { title: "Make-DE Bearing", field: "make-DE_Bearing" },
        { title: "Make-NDE Bearing", field: "make-NDE_Bearing" },
        { title: "BTD Res.", field: "btD_Res." },
        { title: "ACH Res.", field: "acH_Res." },
        { title: "PTC Res.", field: "ptC_Res." },
        { title: "RTD Res.", field: "rtD_Res." },
        { title: "KTY Res.", field: "ktY_Res." },
        { title: "Nameplate Regul ISI ", field: "nameplate_Regul_ISI" },
        { title: "MAX. % UNBALANCE NLC", field: "maX%_UNBALANCENLC" },
    ];

    const [openErrorDialog, setOpenErrorDialog] = useState(false);
    const [openAlertDialog, setOpenAlertDialog] = useState(false);
    const handleAlertClose = () => {
        setSelectedFile(null);
        setOpenAlertDialog(false);
        setIsLoading(false);
    };
    const [alertMessage, setAlertMessage] = useState("");
    const handleAlertOpen = (message) => {
        setOpenAlertDialog(true);
        setAlertMessage(message);
    };
    const handleErrorClose = () => {
        setOpenErrorDialog(false);
    };

    const sample1 = [
        { macNo: 1256, place: "Chennai" },
        { macNo: 12567, place: "Delhi" },
    ];
    return (
        <>
            <div>
                <Grid container spacing={2}>
                    <Grid
                        container
                        spacing={2}
                        item
                        xs={12}
                        className="d-flex jc-space-bt"
                    >
                        <Grid item xs={12} className="text-left ml-1">
                            <h2>Create RTC</h2>
                        </Grid>
                        <Grid container spacing={2} xs={12}>
                            <Grid item xs={12} md={3}>
                                <TextField
                                    id="RTCUploadFile"
                                    variant="filled"
                                    className="bulk-upload"
                                    type="file"
                                    name="bulkUpload"
                                    key={inputFileValue}
                                    onChange={(e) => filePathset_Value(e)}
                                />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Button
                                    className="pt-button--primary bulk-upload-submit-btn"
                                    type="submit"
                                    // disabled={selectedFile ? false : true}
                                    onClick={submitDatacall}
                                >
                                    Upload
                                </Button>
                            </Grid>

                            <Grid container xs={12}>
                                <Grid item xs={12} md={3}>
                                    <a
                                        href={RTC_ManualUpload}
                                        download="RTC_ManualUpload"
                                        target="_blank"
                                    >
                                        <Button className="pt-button--primary bulk-upload-submit-btn">
                                            Download Template
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                xs={12}
                            //   className="d-flex jc-space-bt"
                            >
                                <Grid item xs={12} md={2}>
                                    <a
                                        href={ViewRTC}
                                        onClick={(event) => (window.location.href = "/ViewRTC")}
                                        target="_blank"
                                    >
                                        <Button className="pt-button--primary bulk-upload-submit-btn">
                                            View RTC Data
                                        </Button>
                                    </a>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12}>
                        <h4>
                            Inserted/Updated successfully :{" "}
                            {uploadRes.successCnt ? uploadRes.successCnt : "0"}{" "}
                        </h4>
                        <h4>
                            Failed Records : {uploadRes.failedCnt ? uploadRes.failedCnt : "0"}
                        </h4>
                    </Grid>

                    <div className="marginTop">
                        <Grid item xs={12}>
                            <MaterialTable
                                title="Failed RTC List"
                                columns={columns}
                                data={uploadRes.rtcFailedRecord}
                                icons={tableIconsInputScreen}
                                //isLoading={isLoader}
                                options={{ tableOptionsUser, exportButton: { csv: true } }}
                            />
                        </Grid>
                    </div>
                </Grid>
                {isLoading ? <Loader loaderText="Loading...!" /> : <></>}

                <Dialog
                    onClose={handleAlertClose}
                    disableBackdropClick
                    aria-labelledby="rtc-form--error"
                    open={openAlertDialog}
                    maxWidth={"lg"}
                >
                    <DialogTitle id="rtc-form--error" onClose={handleErrorClose}>
                        Alert:-
                    </DialogTitle>
                    <DialogContent>
                        {existingMacNos.length > 0 ? (
                            <>
                                <p>Machine Numbers mentioned below are already existing.</p>
                                {existingMacNos.map((sample) => (
                                    <span>{sample.machine_No}<br/></span>
                                ))}
                                <p>
                                    In order to make changes to existing Machine Numbers, click on
                                    Submit button.
                                </p>
                            </>
                        ) : (
                            <>
                                <p>{alertMessage}</p>
                            </>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            type="submit"
                            fullWidth
                            className="pt-button--secondary"
                            id="submitbtn"
                            onClick={handleAlertClose}
                        >
                            Close
                        </Button>
                        {existingMacNos.length > 0 ? (
                            <Button
                                type="submit"
                                fullWidth
                                className="pt-button--primary"
                                id="submitbtn"
                                onClick={submitValueDetails}
                            >
                                Submit
                            </Button>
                        ) : (
                            <></>
                        )}
                    </DialogActions>
                </Dialog>
            </div>

            <SnackbarCustom
                open={open}
                message={snackMessage}
                alertType={alertType}
                handleClose={handleClose}
            />
        </>
    );
}
