import React, { useState, useEffect } from "react";
import { Grid, TextField, Button, IconButton, Tooltip } from "@material-ui/core";
import * as FileSaver from 'file-saver';
import axios from "axios";
import { tableIconsInputScreen } from "../../Utilities/Utility";
import MaterialTable from "material-table";
import PictureAsPdfOutlinedIcon from '@material-ui/icons/PictureAsPdfOutlined';
import Loader from "../../Components/Loader/Loader";
import { useSelector } from "react-redux";
export default function GenerateRTC() {
    const api = axios.create({
        baseURL: "api/RTCAPI",
    });
    const userDetails = useSelector(
        (state) => state.storeUserDetailsReducer.userData
    );
    //useEffect(() => {
    //    getBarCodeDetails();
    //}, []);
    const siteToken = useSelector((state) => state.saveBearerTokenReducer);
    const siemensToken = useSelector((state) => state.saveTokenReducer);
    const UID = useSelector((state) => state.saveUserIdReducer);
    const [machineNum, setMachineNum] = useState("");
    const [soNo, setSoNo] = useState("");
    // const [soItem, setSoItem] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [matTableData, setMatTableData] = useState([]);
    const downloadIconButton = (rowData) => {
        return <IconButton className='custIconButton'
            aria-label="InfoOutlined" >
            <Tooltip title="Download PDF" placement="start-top">
                <div>
                    <PictureAsPdfOutlinedIcon
                        className='downloadIcon'
                        onClick={() => downloadPDFIconClick(rowData)} />
                </div>
            </Tooltip>
        </IconButton >
    }

    const columns = [
        { title: "Machine No", field: "machineNo" },
        //{ title: "Client", field: "client" },
        //{ title: "Purchase Order", field: "purchaseOrder" },
        //{ title: "PO Item", field: "poItem" },
        //{ title: "Efficiency Class", field: "effiClass" },
        { title: "Sales Order", field: "soNumber" },
        { title: "Sales Order item", field: "soItem" },
        { title: "Product Code", field: "productCode" },
        { title: "Z Code", field: "zCode" },
        { title: "Output", field: "output" },
        //{ title: "Current", field: "current" },
        { title: "Voltage", field: "voltage" },
        //{ title: "Tolerance For Voltage%", field: "toleranceOfVoltage" },
        { title: "Speed", field: "speed" },
        { title: "Efficiency Class", field: "effiClass" },
        //{ title: "Frequency", field: "frequency" },
        //{ title: "Tolerance For Frequency%", field: "toleranceOfFrequency" },
        //{ title: "Efficiency %", field: "effiPercentage" },
        //{ title: "Power Factor", field: "powerFactor" },
        //{ title: "Connection", field: "connection" },
        //{ title: "Protection", field: "protection" },
        //{ title: "Duty", field: "duty" },
        //{ title: "Insulation Class", field: "insulationClass" },
        //{ title: "Frame Size", field: "frameSize" },
        //{ title: "Ambient Temp.", field: "ambientTemp" },
        //{ title: "Ref.std.", field: "refStd" },
        //{ title: "Direction", field: "direction" },
        //{ title: "DE Bearing", field: "deBearing" },
        //{ title: "NDE Bearing", field: "ndeBearing" },
        //{ title: "Construction", field: "construction" },
        //{ title: "T.Box Position", field: "tboxPossition" },
        //{ title: "Motor TYpe", field: "motorType" },
        //{ title: "Y82", field: "y82" },
        //{ title: "VFD", field: "vfd" },
        //{ title: "BTD", field: "btd" },
        //{ title: "ACH", field: "ach" },
        //{ title: "PTC", field: "ptc" },
        ////{ title: "RTD", field: "rtd" },
        //{ title: "Z Code", field: "zCode" },
        //{ title: "Polarity", field: "polarity" },
        //{ title: "Connection", field: "connection" },
        //{ title: "Avg.Res.", field: "avgRes" },
        //{ title: "Amb.Temp.", field: "ambTemp" },
        //{ title: "Voltage NLTest", field: "voltageNlTest" },
        //{ title: "Current_1 NLTest", field: "current1nltest" },
        //{ title: "Current_2 NLTest", field: "current2nltest" },
        //{ title: "Current_3 NLTest", field: "current3nltest" },
        //{ title: "Input Power NLTest", field: "inputPowerNltest" },
        //{ title: "Voltage LRTest", field: "voltageLrtest" },
        //{ title: "Current LRTest", field: "currentLrtest" },
        //{ title: "Input Power LRTest", field: "inputPowerLrtest" },
        //{ title: "Voltage HVTest", field: "voltageHvtest" },
        //{ title: "Duration HVTest", field: "durationHvtest" },
        //{ title: "Remarks HVTest", field: "remarksHvtest" },
        //{ title: "Insulation Resistance", field: "insuResistance" },
        //{ title: "Insulation Resistance Accessories", field: "insulationResistanceAccessories" },
        //{ title: "Date", field: "date" },
        //{ title: "Vibration", field: "vibration" },
        //{ title: "EDesign/Calculation Sheet", field: "edesignCalc" },
        //{ title: "Make-DE Bearing", field: "makeDeBearing" },
        //{ title: "Make-NDE Bearing", field: "makeNdeBearing" },
        //{ title: "BTD Res.", field: "btdRes" },
        //{ title: "ACH Res.", field: "achRes" },
        //{ title: "PTC Res.", field: "ptcRes" },
        //{ title: "RTD Res.", field: "rtdRes" },
        //{ title: "KTY Res.", field: "ktyRes" },
        //{ title: "Nameplate Regul ISI ", field: "nameplateRegulIsi" },
        //{ title: "MAX. % UNBALANCE NLC", field: "maxUnbalanceNlc" },
        { title: "Download", field: "download", render: (rowData) => downloadIconButton(rowData) },
    ];

    function machineNumChange(e) {
        console.log("Mac Value", e.target.value);
        setMachineNum(e.target.value);
    }
    function soNoChange(e) {
        console.log("SO NO", e.target.value);
        setSoNo(e.target.value);
    }
    // function soItemChange(e) {
    //     console.log("SO Item", e.target.value);
    //     setSoItem(e.target.value);
    // }


    //const MachineNo = 65273236;
    function downloadPDFIconClick(rowData) {
        const options = {
            method: "get",
            headers: {
                'Content-Type':'application/json',
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };
        api
            .get("/GenerateBarCode/" + rowData.machineNo, options)
            .then((res) => {
                console.log(res.data);
            })
            .catch((error) => {
                console.log(error.response);
                console.log("Error in hitting api at page load.", error);
            })
            .finally(() => {
                downloadRTCpdf(rowData);
            });
        console.log("Download PDF Clicked");
    }

    //function downloadRTCpdf(rowData) {
    //    let paramDetails = {};
    //    paramDetails = {
    //        "MachineNumber": rowData.machineNo,
    //        "RTCId": rowData.rtcId
    //    }
    //    api.post('/getRTCReportDetailsPDF', paramDetails, { responseType: 'arraybuffer' })
    //        .then(res => {
    //            var blob = new Blob([res.data], {
    //                type: "application/pdf",

    //            });
    //            FileSaver.saveAs(blob, rowData.machineNo + "_RTCReport.pdf");
    //        });
    //}
    function downloadRTCpdf(rowData) {
        let paramDetails = {
            "MachineNumber": rowData.machineNo,
            "RTCId": rowData.rtcId
        };

        const options = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };

        api.post('/getRTCReportDetailsPDF', paramDetails, options)
            .then(res => {
                var blob = new Blob([res.data], {
                    type: "application/pdf"
                });
                FileSaver.saveAs(blob, rowData.machineNo + "_RTCReport.pdf");
            })
            .catch(error => {
                console.error('Error downloading PDF:', error);
            });
    }
    function getCurrentDateTime() {
        const now = new Date();

        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        };

        const formatter = new Intl.DateTimeFormat('en-IN', options);
        const formattedDateTime = formatter.format(now).trim();

        return formattedDateTime.replace(', ', '-');
    }
    //function downloadMultiplePDF(data) {
    //    const fileNameCurrDate = getCurrentDateTime();
    //    api.post('/DownloadAllRTCDetailsPDF', data, { responseType: 'arraybuffer' })
    //        .then(res => {
    //            var blob = new Blob([res.data], {
    //                type: "application/pdf",
    //            });
    //            FileSaver.saveAs(blob, "RTCReport-" + fileNameCurrDate + ".zip");
    //            setIsLoading(false);
    //        });
    //}
    function downloadMultiplePDF(data) {
        const fileNameCurrDate = getCurrentDateTime();

        const options = {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
        };

        api.post('/DownloadAllRTCDetailsPDF', data, options)
            .then(res => {
                var blob = new Blob([res.data], {
                    type: "application/pdf",
                });
                FileSaver.saveAs(blob, "RTCReport-" + fileNameCurrDate + ".zip");
                setIsLoading(false);
            });
    }




    const searchComplaint = () => {
        setIsLoading(true);
        // const macNoArray = machineNum?.split(",").map(str => str.trim()) || [];
        let searchArgs = {
            MachineNumber: machineNum,
            SoNumber: soNo,
            // SoItem: soItem,
        };
        const options = {
            method: "post",
            headers: {
                Accept: "application/json, text/plain, */*",
                "Content-Type": "application/json",
                Authorization: "Bearer " + siteToken.token,
                SiemensToken: siemensToken.token,
                UserEmail: userDetails.emailId,
                RoleName: userDetails.roleName,
                Gid: userDetails.gid,
                Roleid: userDetails.roleId,
                userId: UID.id,
            },
            //body: JSON.stringify(searchArgs),
        };
        if (machineNum !== "" || soNo !== "") {
            api
                .post("/SearchRoutineListForReport", searchArgs, options)
                .then(function (response) {
                    setMatTableData(response.data.result);
                    console.log("RTC List Details For Report", response.data.result);
                    setIsLoading(false);
                })
                .catch(function (error) {
                    console.log("Error at api", error);
                    setIsLoading(false);
                });
        }
        else {
            setIsLoading(false);
        }
    };

    const tableHeaderStyle = {
        backgroundColor: "var(--light-sand)",
    };
    const tableOptions = {
        headerStyle: tableHeaderStyle,
        showTitle: false,
        draggable: false,
        pageSize: 3,
        pageSizeOptions: [5, 10, 25, 50],
        paginationPosition: "bottom",
        maxBodyHeight: "600px",
        selection: true
    };

    const downloadFiles = (data) => {
        setIsLoading(true);
        const selectedRTCId = data.map(item => item.rtcId);
        downloadMultiplePDF(selectedRTCId);
    }
    const renderDownloadSelected = () => {
        return (
            <Button
                className="pt-button--primary bulk-upload-submit-btn"
                type="button"
                fullWidth
            >
                Download All
            </Button>
        )
    }
    return (
        <>
            <Grid container spacing={2} item xs={12} className="d-flex jc-space-bt">
                <Grid item xs={12} className="text-left ml-1">
                    <h2>Generate RTC</h2>
                </Grid>
            </Grid>
            <Grid container spacing={2} className="mt-05">
                <Grid item xs={12} sm={5} md={5}>
                    <TextField
                        className=""
                        fullWidth
                        name="machineNum"
                        label="Enter Machine Number"
                        variant="filled"
                        type="text"
                        value={machineNum}
                        onChange={(e) => machineNumChange(e)}
                        id="machineNum"
                    />
                </Grid>
                <Grid className="mt-05">
                    <h4>OR</h4>
                </Grid>

                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="soNo"
                        label="Enter SO Number"
                        variant="filled"
                        type="text"
                        value={soNo}
                        onChange={(e) => soNoChange(e)}
                        id="soNo"
                    />
                </Grid>
                {/* <Grid className="mt-05">
                    <h4>AND</h4>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                    <TextField
                        className=""
                        fullWidth
                        name="soItem"
                        label="Enter SO Item"
                        variant="filled"
                        type="text"
                        value={soItem}
                        onChange={(e) => soItemChange(e)}
                        id="soItem"
                    />
                </Grid> */}

                <Grid item xs={12} md={2} sm={2} fullWidth>
                    <Button
                        className="pt-button--primary bulk-upload-submit-btn"
                        type="button"
                        onClick={searchComplaint}
                        fullWidth
                    >
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12} className="matTableCont">
                    <MaterialTable
                        className="matTable"
                        title="User List"
                        columns={columns}
                        data={matTableData}
                        icons={tableIconsInputScreen}
                        //isLoading={isLoader}
                        options={tableOptions}
                        actions={[
                            {
                                icon: () => renderDownloadSelected(),
                                onClick: (evt, data) => downloadFiles(data),
                                tooltip: 'Download report for selected items',
                            }
                        ]}
                    />
                </Grid>
            </Grid>
            {isLoading ? <Loader loaderText="Loading...!" /> : <></>}
        </>
    );
}
